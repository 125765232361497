<template>
  <div
    class="table-responsive pca-table"
    :style="{ '--border-color': borderColor }"
  >
    <table :class="['table', tableClass]" :style="tableStyle">
      <thead :class="theadClass">
        <tr>
          <slot name="thead" />
        </tr>
      </thead>

      <slot />
    </table>
  </div>
</template>

<script>
export default {
  name: 'TableBorder',
  props: {
    borderColor: {
      type: String,
      default: () => '',
    },
    tableClass: {
      type: String,
      default: () => '',
    },
    tableStyle: {
      type: [String, Object],
    },
    theadClass: {
      type: String,
      default: 'thead-light',
    },
  },
}
</script>

<style lang="scss" scoped>
.pca-table {
  --border-color: #f8f9fa;
  font-size: 13px;

  table td,
  table th {
    vertical-align: middle;
  }

  table thead th {
    border-top-color: var(--border-color);
    border-bottom-color: var(--border-color);
  }

  .table td,
  .table tbody tr:first-child td {
    border-top: 7px solid var(--border-color);
    // background-color: #fff;
  }

  .table:not(.table-hover),
  .table.table-hover tr:not(:hover) {
    background-color: #fff;
  }
}
</style>