<template>
  <a-modal
    :visible="visible"
    :wrap-class-name="$style.modalSync"
    :closable="false"
    :width="400"
  >
    <template v-if="showSuccessIntegrate">
      <img :src="require(`@/assets/store/add-store/success-add.svg`)" style="width:60px !important; height:60px !important;" />
      <h4 class="mt-4">
        Otorisasi Toko Berhasil!
      </h4>
      <div class="mt-4 w-100 text-center">
        Berhasil mensinkronisasikan produk dan pesanan dari toko.
      </div>
    </template>
    <template v-else>
      <h5 class="text-center w-100 mt-4">
        Approve Store Integrations
      </h5>
    </template>
    <template #footer>
      <template v-if="showSuccessIntegrate">
        <a-button
          type="primary"
          @click="$emit('emitSuccessApprove', null)"
        >
          OK
        </a-button>
      </template>
      <template v-else>
        <a-button
          class="mr-2"
          @click="openModal(null)"
        >
          Kembali
        </a-button>
        <a-button
          type="primary"
          form="form-integrate"
          :loading="integration.loadingSync"
          @click.prevent="handleSubmitForm"
        >
          Approve Store
        </a-button>
      </template>
    </template>
  </a-modal>
</template>

<script>
export default {
  name: 'ModalApproveConfirmation',
  components: {
  },
  props: {
    visible: Boolean,
    openModal: Function,
    openModalSuccess: Function,
    selectedData: Object,
  },
  data: function () {
    return {
      showSuccessIntegrate: false,
    }
  },
  computed: {
    integration() {
      return this.$store.state.integration
    },
  },
  methods: {
    handleSubmitForm() {
      if (this.selectedData?.sales_channel?.name === 'Tokopedia') {
        this.$store.dispatch('integration/CONFIRMATIONINTEGRATIONSALESCHANNEL', {
          channelCode: this.selectedData?.sales_channel?.code,
          business: this.$route.query.business_id,
          channelId: this.selectedData?.id,
          aggregator_id: process.env.VUE_APP_AGGREGATOR_ID,
          shop_id: 14840464,
          warehouse_id: '',
        })
        .then((data) => {
          if (data?.channelId && data?.status?.toUpperCase() === 'PENDING_WAREHOUSE') {
            this.showSuccessIntegrate = true
          }
        })
        .catch((err) => {
          this.$notification.error({
            message: 'Connected Failed',
            description: err.response?.data?.message || err.message,
          })
        })
      }
    },
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
