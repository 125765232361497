<template>
  <div>
    <a-pagination
      v-model="page"
      class="pca-pagination"
      :total="total"
      :show-total="() => $t('utils.pagination_show_total', { perpage: totalLengthPerPage, total })"
      :page-size="pageSize"
      @change="handleChangePage"
    />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      type: Number,
    },
    pageSize: {
      type: Number,
    },
    totalLengthPerPage: {
      type: Number,
    },
    defaultPage: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    page: {
      set: function (value) {
        this.$emit('input', value)
        this.$emit('changed', value)
      },
      get: function () {
        return this.defaultPage
      },
    },
  },
  methods: {
    handleChangePage (page) { // , pageSize
      this.$emit('changePage', page)
    },
  },
}
</script>

<style lang="scss">
.pca-pagination {
  .ant-pagination-total-text,
  .ant-pagination-item > a {
    color: #999;
    font-family: 'Poppins', sans-serif !important;
    font-style: normal !important;
    font-size: 14px !important;
  }

  .ant-pagination-item-active > a {
    color: #E00000;
  }
}
</style>
