<template>
  <a-skeleton
    active
    :title="false"
    :paragraph="{ rows, width: '100%' }"
    class="loading-list-table"
  />
</template>

<script>
export default {
  name: 'LoadingListTable',
  props: {
    rows: {
      type: Number,
      default: 5,
    },
  },
}
</script>

<style lang="scss">
.loading-list-table.ant-skeleton ul > li {
  height: 48px !important;
  border-radius: 0px;
  cursor: progress;
}

.loading-list-table.ant-skeleton .ant-skeleton-content ul > li + li {
  margin-top: 9px;
}
</style>
