<template>
  <svg
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.85555 1.03467C1.43107 0.610188 0.742845 0.610188 0.318362 1.03467C-0.106121 1.45915 -0.106121 2.14738 0.318362 2.57186L3.46281 5.71631L0.318362 8.86076C-0.106121 9.28524 -0.106121 9.97346 0.318362 10.3979C0.742845 10.8224 1.43107 10.8224 1.85555 10.3979L5 7.2535L8.14445 10.3979C8.56893 10.8224 9.25715 10.8224 9.68164 10.3979C10.1061 9.97346 10.1061 9.28524 9.68164 8.86076L6.53719 5.71631L9.68164 2.57186C10.1061 2.14738 10.1061 1.45915 9.68164 1.03467C9.25715 0.610188 8.56893 0.610188 8.14445 1.03467L5 4.17912L1.85555 1.03467Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'Close',
  props: {
    color: {
      type: String,
      default: () => '#E00000',
    },
  },
}
</script>
