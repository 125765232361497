<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
  >
    <path d="M7.49967 0.333252C6.72837 0.333252 6.1031 0.95852 6.1031 1.72983V5.60334H2.22958C1.45828 5.60334 0.833008 6.22861 0.833008 6.99992C0.833008 7.77123 1.45828 8.39649 2.22958 8.39649H6.1031V12.27C6.1031 13.0413 6.72837 13.6666 7.49967 13.6666C8.27098 13.6666 8.89625 13.0413 8.89625 12.27V8.39649H12.7698C13.5411 8.39649 14.1663 7.77123 14.1663 6.99992C14.1663 6.22861 13.5411 5.60334 12.7698 5.60334H8.89625V1.72983C8.89625 0.95852 8.27098 0.333252 7.49967 0.333252Z" :fill="color" />
  </svg>
</template>

<script>
export default {
  name: 'PlusIcon',
  props: {
    color: {
      type: String,
      default: () => '#E00000',
    },
  },
}
</script>

<style>

</style>
