<template>
  <div class="container d-flex flex-column justify-content-center align-items-center emptyInfo">
    <img
      alt="No data"
      class="img-fluid"
      draggable="false"
      :src="imgSrc"
    />
    <div v-if="title" class="mt-3 pbz-font subtitle-md-medium">
      {{ title }}
    </div>

    <div v-if="description" class="mt-3 pbz-font body-md-regular text-secondary">
      {{ description }}
    </div>

    <ConsoleLink
      v-if="addUrl"
      :to="{ path: addUrl }"
      class="ant-btn ant-btn-lg ant-btn-danger px-4 mt-4"
    >
      {{ buttonLabel }}
    </ConsoleLink>

    <slot />
  </div>
</template>

<script>
import ConsoleLink from '@/components/ConsoleLink/ConsoleLink.vue'
export default {
  components: {
    ConsoleLink,
  },
  props: {
    imgSrc: {
      type: String,
      default: '/media/img/empty_state.png', // () => 'https://ik.imagekit.io/powerbiz/img/admin-saas/Store.png?updatedAt=1635392034840',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    addUrl: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      default: '',
    },
  },
}
</script>
