<template>
  <a-result
    :status="['404', '403', '500'].includes('' + errorType) ? '' + errorType : 'error'"
    :title="errorType"
    :sub-title="$t('utils.wrong')"
  >
    <template #extra>
      <a-button
        type="primary"
        size="large"
        @click="reload"
      >
        Reload
      </a-button>
    </template>
  </a-result>
</template>

<script>
export default {
  name: 'ErrorInfo',
  props: ['errorType'],
  methods: {
    reload() {
      window.location.reload()
    },
  },
}
</script>
